import './pages/brands.js';
import './pages/brands-catalog.js';
import './pages/cart.js';
import './pages/categories-catalog.js';
import './pages/categories.js';
import './pages/main.js';
import './pages/search.js';
import './modules/quick-search.js';
import './modules/read-more.js';

const bodyElement = document.body;
const pageElement = document.querySelector('.page');
const headerElement = document.querySelector('.header');
const dropdownElements = document.querySelectorAll('.dropdown');
const dropdownButtonElements = document.querySelectorAll('.dropdown__button');
const catalogAsideElement = document.querySelector('.catalog__aside');
const cartAsideElement = document.querySelector('.cart__aside');
let prevScroll = window.pageYOffset;

document.documentElement.className = 'js';

const supportsCssVars = () => {
	const styleElement = document.createElement('style');
	styleElement.innerHTML = 'root: { --tmp-var: bold; }';
	document.head.append(styleElement);
	const supported = !!(window.CSS && window.CSS.supports && window.CSS.supports('font-weight', 'var(--tmp-var)'));
	styleElement.parentNode.removeChild(styleElement);
	return supported;
};

const headerHidden = () => headerElement.classList.contains('header--hidden');

const setCatalogAsideHeight = () => {
	const screenWidth = window.innerWidth;
	if (screenWidth <= 1024) {
		return;
	}
	if (!catalogAsideElement || !headerElement) return;
	const screenHeight = window.innerHeight;
	const headerHeight = headerElement.offsetHeight;
	catalogAsideElement.style.maxHeight = headerHidden() ? `calc(100vh - var(--space-sm))` : `calc(100vh - ${headerHeight}px)`;
	catalogAsideElement.style.top = headerHidden() ? 'var(--space-sm)' : `${headerHeight}px`;
};

const setCartAsideHeight = () => {
	const screenWidth = window.innerWidth;
	if (screenWidth <= 1024) {
		return;
	}
	if (!cartAsideElement || !headerElement) return;
	const screenHeight = window.innerHeight;
	const headerHeight = headerElement.offsetHeight;
	cartAsideElement.style.top = headerHidden() ? 'var(--space-sm)' : `${headerHeight}px`;
};

const onScrollHeader = () => {
	window.addEventListener('scroll', () => {
		const currentScroll = window.pageYOffset;
		if (currentScroll > prevScroll && !headerHidden()) {
			headerElement.classList.add('header--hidden');
		} else if (currentScroll < prevScroll && headerHidden()) {
			headerElement.classList.remove('header--hidden');
		}
		prevScroll = currentScroll;
	});
};

/*const insertHtml = (url, position) => {
	fetch(url)
		.then((response) => response.text())
		.then((data) => {
			if (position === 'beforeend') {
				pageElement.insertAdjacentHTML('beforeend', data);
			} else {
				bodyElement.insertAdjacentHTML('afterbegin', data);
			}
		});
};*/

if (!supportsCssVars()) {
	alert('Браузер не поддерживает CSS-переменные.');
}

/*document.addEventListener('DOMContentLoaded', () => {
	const path = window.location.pathname;
	if (path.includes('/brands/') || path.includes('/categories/')) {
		insertHtml('../includes/footer.html', 'beforeend');
		insertHtml('../includes/svg.html', 'afterbegin');
	} else if (path.endsWith('index.html')) {
		insertHtml('./includes/index-footer.html', 'beforeend');
		insertHtml('./includes/svg.html', 'afterbegin');
	} else {
		insertHtml('./includes/footer.html', 'beforeend');
		insertHtml('./includes/svg.html', 'afterbegin');
	}
});*/

setCatalogAsideHeight();

setCartAsideHeight();

onScrollHeader();

window.addEventListener('resize', () => {
	setCatalogAsideHeight();
	setCartAsideHeight();
});

window.addEventListener('scroll', () => {
	setCatalogAsideHeight();
	setCartAsideHeight();
});

const toggleDropdown = (index) => {
	dropdownElements.forEach((dropdown, i) => {
		if (i !== index) {
			dropdown.classList.remove('dropdown--open');
		}
	});
	dropdownElements[index]?.classList.toggle('dropdown--open');
};

const closeAllDropdowns = () => {
	dropdownElements.forEach((dropdown) => {
		dropdown.classList.remove('dropdown--open');
	});
};

dropdownButtonElements.forEach((button, index) => {
	button.addEventListener('click', () => {
		toggleDropdown(index);
	});
});

document.addEventListener('click', (event) => {
	if (!event.target.closest('.dropdown') && !event.target.closest('.dropdown__button')) {
		closeAllDropdowns();
	}
});

document.addEventListener('keydown', (event) => {
	if (event.key === 'Escape') {
		closeAllDropdowns();
	}
});
