﻿import { showProductDetails } from '../pages/brands-catalog.js';
import { splitIntegers } from '../modules/split-integers.js';

const addToCart = (product) => {
	const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
	const existingItem = cartItems.find((item) => item.productId === product.id);
	if (existingItem) {
		existingItem.quantity++;
	} else {
		cartItems.push({
			productId: product.id,
			brandId: product.brandId,
			name: product.name,
			price: product.price,
			quantity: 1,
		});
	}
	localStorage.setItem('cart', JSON.stringify(cartItems));
};

const countCartItems = () => {
	const cartCountElement = document.querySelector('.header__cart-count');
	if (!cartCountElement) {
		return;
	}
	const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
	const itemCount = cartItems.reduce((total, item) => total + item.quantity, 0);
	if (itemCount > 9) {
		cartCountElement.textContent = '9+';
	} else {
		cartCountElement.textContent = itemCount;
	}
	cartCountElement.style.display = itemCount === 0 ? 'none' : 'inline-flex';
};

const handleMinusClick = (inputElement, product) => {
	if (inputElement.value > 1) {
		inputElement.value--;
		const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
		const existingItemIndex = cartItems.findIndex((item) => item.productId === product.id);
		if (existingItemIndex !== -1) {
			cartItems[existingItemIndex].quantity--;
			localStorage.setItem('cart', JSON.stringify(cartItems));
			countCartItems();
		}
	} else {
		const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
		const existingItemIndex = cartItems.findIndex((item) => item.productId === product.id);
		if (existingItemIndex !== -1) {
			cartItems.splice(existingItemIndex, 1);
			localStorage.setItem('cart', JSON.stringify(cartItems));
			countCartItems();
		}
		const actionsElement = inputElement.parentNode.parentNode;
		const buttonElement = actionsElement.querySelector('.product-card__button, .product-card-v2__button, .product-details__button');
		buttonElement.style.display = 'inline-flex';
		inputElement.parentNode.remove();
	}
};

const handlePlusClick = (inputElement, product) => {
	inputElement.value++;
	addToCart(product);
	countCartItems();
};

// Создаем карточку товара
const createProductCardLink = (product, brandId, products) => {
	const linkElement = document.createElement('a');
	linkElement.classList.add('product-card__link');
	linkElement.href = `/brands/catalog?brand=${brandId}&product=${product.id}`;
	linkElement.addEventListener('click', (event) => {
		if (window.location.pathname.includes('/brands/catalog') && !isInsideCardButton(event.target) && !isInsideCardCounter(event.target)) {
			event.preventDefault();
			showProductDetails(product, brandId, products);
			window.history.pushState(null, null, linkElement.href);
		} else {
			return;
		}
	});
	const isInsideCardButton = (element) => {
		return element.closest('.product-card__button') !== null;
	};
	const isInsideCardCounter = (element) => {
		return element.closest('.product-card__counter') !== null;
	};
	return linkElement;
};

const createProductCardAside = (product) => {
	const asideElement = document.createElement('aside');
	asideElement.classList.add('product-card__aside');
	const figureElement = document.createElement('figure');
	figureElement.classList.add('product-card__figure');
	const imageElement = document.createElement('img');
	imageElement.alt = product.name;
	imageElement.classList.add('product-card__image');
	if (!product.image || product.image === '') {
		imageElement.src = '004.jpg';
	} else {
		imageElement.src = product.image;
	}
	figureElement.append(imageElement);
	asideElement.append(figureElement);
	return asideElement;
};

const createProductCardHeader = (product) => {
	const headerElement = document.createElement('header');
	headerElement.classList.add('product-card__header');
	const priceElement = document.createElement('div');
	priceElement.classList.add('product-card__price');
	priceElement.textContent = `${splitIntegers(product.price)} ₽`;
	const nameElement = document.createElement('div');
	nameElement.classList.add('product-card__name');
	nameElement.textContent = product.name;
	headerElement.append(priceElement, nameElement);
	return headerElement;
};

const createProductCardFooter = (product, brandId) => {
	const footerElement = document.createElement('footer');
	footerElement.classList.add('product-card__footer');
	const actionsElement = document.createElement('div');
	actionsElement.classList.add('product-card__actions');
	actionsElement.addEventListener('click', (event) => {
		event.preventDefault();
	});
	const buttonElement = document.createElement('button');
	buttonElement.classList.add('product-card__button', 'button', 'button--md', 'button--primary');
	buttonElement.type = 'button';
	const buttonTextElement = document.createElement('span');
	buttonTextElement.classList.add('button__text');
	buttonTextElement.textContent = 'В корзину';
	const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
	const existingItemIndex = cartItems.findIndex((item) => item.productId === product.id);
	if (existingItemIndex !== -1) {
		buttonElement.style.display = 'none';
		const inputElement = createProductCardInput(actionsElement);
		inputElement.value = cartItems[existingItemIndex].quantity;
		const counterElement = createProductCardCounter(actionsElement, inputElement, product, brandId);
	} else {
		buttonElement.addEventListener('click', (event) => {
			event.preventDefault();
			addToCart(product);
			countCartItems();
			buttonElement.style.display = 'none';
			const inputElement = createProductCardInput(actionsElement);
			const counterElement = createProductCardCounter(actionsElement, inputElement, product, brandId);
		});
	}
	buttonElement.append(buttonTextElement);
	actionsElement.append(buttonElement);
	footerElement.append(actionsElement);
	return footerElement;
};

const createProductCardInput = (actionsElement) => {
	const inputElement = document.createElement('input');
	inputElement.classList.add('input');
	inputElement.disabled = 'disabled';
	inputElement.min = '1';
	inputElement.type = 'number';
	inputElement.value = 1;
	actionsElement.append(inputElement);
	return inputElement;
};

const createProductCardCounter = (actionsElement, inputElement, product, brandId) => {
	const counterElement = document.createElement('div');
	counterElement.classList.add('product-card__counter');
	const buttonMinusElement = document.createElement('button');
	buttonMinusElement.classList.add('product-card__button-minus', 'button');
	buttonMinusElement.innerHTML = `<svg class="product-card__button-icon" height="24" width="24"><use href="#minusIcon"></use></svg>`;
	buttonMinusElement.addEventListener('click', () => {
		handleMinusClick(inputElement, product, brandId);
	});
	const buttonPlusElement = document.createElement('button');
	buttonPlusElement.classList.add('product-card__button-plus', 'button');
	buttonPlusElement.innerHTML = `<svg class="product-card__button-icon" height="24" width="24"><use href="#plusIcon"></use></svg>`;
	buttonPlusElement.addEventListener('click', () => {
		handlePlusClick(inputElement, product, brandId);
	});
	counterElement.append(buttonMinusElement, inputElement, buttonPlusElement);
	actionsElement.append(counterElement);
};

const createProductCard = (product, brandId, products, className = '') => {
	const cardElement = document.createElement('li');
	cardElement.classList.add('product-card');
	if (className) {
		const classes = className.split(' ');
		classes.forEach((c) => cardElement.classList.add(c));
	}
	const linkElement = createProductCardLink(product, brandId, products);
	const asideElement = createProductCardAside(product);
	const headerElement = createProductCardHeader(product);
	const footerElement = createProductCardFooter(product, brandId);
	linkElement.append(asideElement, headerElement, footerElement);
	cardElement.append(linkElement);
	return cardElement;
};

countCartItems();

export { addToCart, countCartItems, createProductCardInput, createProductCardCounter, createProductCard };
