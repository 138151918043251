﻿const splitIntegers = (number) => {
	const stringNumber = number.toString();
	let result = '';
	if (stringNumber.length <= 4) {
		return stringNumber;
	}
	for (let i = 0; i < stringNumber.length; i++) {
		if (i !== 0 && (stringNumber.length - i) % 3 === 0) {
			result += ' ';
		}
		result += stringNumber[i];
	}
	return result;
};

export { splitIntegers };
