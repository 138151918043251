﻿const DEFAULT_CHARS = 200;
const truncateElements = document.querySelectorAll('.truncate');

const truncateText = () => {
	const chars = parseInt(document.querySelector('[data-characters]')?.dataset.characters) || DEFAULT_CHARS;
	truncateElements.forEach((truncateElement) => {
		const { textContent } = truncateElement;
		if (textContent.length > chars) {
			const truncatedText = `${textContent.slice(0, chars).trim()} <span class="truncate__text">${textContent.slice(chars).trim()}</span> `;
			truncateElement.innerHTML = truncatedText;
			const linkElement = document.createElement('a');
			linkElement.href = '#';
			linkElement.classList.add('truncate__more');
			linkElement.textContent = 'Читать ещё!';
			truncateElement.append(linkElement);
			const hiddenTextElement = truncateElement.querySelector('.truncate__text');
			hiddenTextElement.classList.add('truncate__text--hidden');
		}
	});
};

const toggleHiddenText = (event) => {
	if (event.target.matches('.truncate__more')) {
		event.preventDefault();
		const truncateElement = event.target.parentNode;
		const hiddenTextElement = truncateElement.querySelector('.truncate__text');
		hiddenTextElement.classList.toggle('truncate__text--hidden');
		event.target.textContent = hiddenTextElement.classList.contains('truncate__text--hidden') ? 'Читать ещё!' : 'Скрыть';
	}
};

document.addEventListener('DOMContentLoaded', () => {
	if (!truncateElements.length) return;
	truncateText();
	document.addEventListener('click', toggleHiddenText);
});

export { truncateText, toggleHiddenText };
