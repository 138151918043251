﻿import '../libs/fuse.js';
import { createBrandCard } from '../constructors/brand-card.js';
import { createProductCard } from '../constructors/product-card.js';
import { fetchData } from '../modules/fetch-data.js';

const searchResultsElement = document.getElementById('search-results');
const searchQuery = new URLSearchParams(window.location.search).get('query');

const search = (searchQuery, manufacturers, products) => {
	const options = {
		keys: ['name', 'price'],
		includeScore: true,
		threshold: 0.5,
	};
	const fuse = new Fuse([...manufacturers, ...products], options);
	const searchResults = fuse.search(searchQuery);
	return searchResults.map((result) => result.item);
};

const createResultsEmpty = (parentElement) => {
	const textElement = document.createElement('div');
	textElement.classList.add('results__text');
	textElement.textContent = 'Ничего не найдено';
	parentElement.append(textElement);
};

const createResultsStart = (parentElement) => {
	const textElement = document.createElement('div');
	textElement.classList.add('results__text');
	textElement.textContent = 'Введите текст в поле поиска, и появится список всего, что нашлось';
	parentElement.append(textElement);
};

const displaySearchResults = (searchQuery, searchResults) => {
	searchResultsElement.innerHTML = '';
	if (searchResults.length === 0) {
		if (!searchQuery || searchQuery.trim() === '') {
			createResultsStart(searchResultsElement);
		} else {
			createResultsEmpty(searchResultsElement);
		}
		return;
	}
	const { manufacturersResults, productsResults } = searchResults.reduce(
		(acc, item) => {
			if (item.hasOwnProperty('brandId')) {
				acc.manufacturersResults.push(item);
			} else {
				acc.productsResults.push(item);
			}
			return acc;
		},
		{
			manufacturersResults: [],
			productsResults: [],
		},
	);
	if (manufacturersResults.length > 0) {
		const sectionElement = document.createElement('section');
		sectionElement.classList.add('results__section', 'results-section', 'results-section--products');
		const titleElement = document.createElement('h2');
		titleElement.classList.add('results-section__title');
		titleElement.textContent = 'Товары';
		const listElement = document.createElement('ul');
		listElement.classList.add('results-section__list');
		manufacturersResults.forEach((product) => {
			const productCard = createProductCard(product, product.brandId);
			listElement.append(productCard);
		});
		sectionElement.append(titleElement, listElement);
		searchResultsElement.append(sectionElement);
	}
	if (productsResults.length > 0) {
		const sectionElement = document.createElement('section');
		sectionElement.classList.add('results__section', 'results-section', 'results-section--brands');
		const titleElement = document.createElement('h2');
		titleElement.classList.add('results-section__title');
		titleElement.textContent = 'Производители';
		const listElement = document.createElement('ul');
		listElement.classList.add('results-section__list');
		productsResults.forEach((result) => {
			const cardElement = createBrandCard(result);
			listElement.append(cardElement);
		});
		sectionElement.append(titleElement, listElement);
		searchResultsElement.append(sectionElement);
	}
};

const loadSearch = async () => {
	try {
		const { manufacturers, products } = await fetchData();
		if (!searchQuery || searchQuery.trim() === '') {
			createResultsStart(searchResultsElement);
			return;
		} else {
			const searchResults = search(searchQuery, manufacturers, products);
			displaySearchResults(searchQuery, searchResults);
		}
	} catch (error) {
		console.error(error);
	}
};

if (window.location.pathname === '/search') {
	loadSearch();
}

export { search, displaySearchResults };
