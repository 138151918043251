﻿const updateBrandsContent = () => {
	const tocLinkActiveElement = document.querySelector('.brands__toc-link--active');
	const brandFilter = tocLinkActiveElement.dataset.filter;
	const brandElements = document.querySelectorAll('.brand-group');
	brandElements.forEach((brandElement) => {
		if (brandFilter === 'all' || brandFilter === brandElement.dataset.letter) {
			brandElement.style.display = 'block';
		} else {
			brandElement.style.display = 'none';
		}
	});
};

const createBrandsToc = (titleElements, asideElement) => {
	const tocElement = document.createElement('div');
	tocElement.classList.add('brands__toc');
	const listElement = document.createElement('ul');
	listElement.classList.add('brands__toc-list');
	const itemElement = document.createElement('li');
	itemElement.classList.add('brands__toc-item');
	const linkActiveElement = document.createElement('a');
	linkActiveElement.classList.add('brands__toc-link', 'brands__toc-link--active');
	linkActiveElement.dataset.filter = 'all';
	linkActiveElement.textContent = 'Все';
	itemElement.append(linkActiveElement);
	listElement.append(itemElement);
	for (const titleElement of titleElements) {
		const itemElement = document.createElement('li');
		itemElement.classList.add('brands__toc-item');
		const linkElement = document.createElement('a');
		linkElement.classList.add('brands__toc-link');
		linkElement.dataset.filter = titleElement.parentNode.dataset.letter;
		linkElement.textContent = titleElement.textContent;
		itemElement.append(linkElement);
		listElement.append(itemElement);
	}
	tocElement.append(listElement);
	asideElement.append(tocElement);
	const linkElements = document.querySelectorAll('.brands__toc-link');
	linkElements.forEach((linkElement) => {
		linkElement.addEventListener('click', () => {
			linkElements.forEach((linkElement) => {
				linkElement.classList.remove('brands__toc-link--active');
			});
			linkElement.classList.toggle('brands__toc-link--active');
			updateBrandsContent();
		});
	});
};

export { createBrandsToc };
