﻿const searchElement = document.querySelector('.search-form');
const searchInputElement = document.querySelector('.search-form__control-input');
const searchClearElement = document.querySelector('.search-form__control-button--clear');
const searchSuggestionElement = document.querySelector('.search-form__suggestion');
const MIN_CHARACTERS = 1;

const clearSearchInput = () => {
	searchInputElement.value = '';
	Array.from(searchSuggestionElement.children)
		.filter((child) => child.nodeType === 1)
		.forEach((child) => child.remove());
	searchElement.classList.remove('search-form--visible');
	searchClearElement.style.display = 'none';
};

const checkSearchInput = () => {
	if (searchInputElement.value !== '') {
		searchElement.classList.add('search-form--visible');
	} else {
		Array.from(searchSuggestionElement.children)
			.filter((child) => child.nodeType === 1)
			.forEach((child) => child.remove());
		searchElement.classList.remove('search-form--visible');
	}
};

const displaySearchSuggestion = (results) => {
	Array.from(searchSuggestionElement.children)
		.filter((child) => child.nodeType === 1)
		.forEach((child) => child.remove());
	if (!results.length) {
		const emptyElement = document.createElement('div');
		emptyElement.classList.add('search-form__suggestion-empty');
		emptyElement.textContent = 'Ничего не найдено';
		searchSuggestionElement.append(emptyElement);
		return;
	}
	const listElement = document.createElement('ul');
	listElement.classList.add('search-form__suggestion-list');
	results.forEach((result) => {
		const itemElement = document.createElement('li');
		itemElement.classList.add('search-form__suggestion-item');
		const linkElement = document.createElement('a');
		linkElement.classList.add('search-form__suggestion-link');
		linkElement.href = result.hasOwnProperty('brandId') ? `/brands/catalog?brand=${result.brandId}&product=${result.id}` : `/brands/catalog?brand=${result.id}`;
		linkElement.textContent = result.name;
		itemElement.append(linkElement);
		listElement.append(itemElement);
	});
	const showMoreElement = document.createElement('div');
	showMoreElement.classList.add('search-form__full');
	const showMoreLinkElement = document.createElement('a');
	showMoreLinkElement.classList.add('search-form__full-link');
	const searchQuery = searchInputElement.value;
	showMoreLinkElement.href = `/search?query=${searchQuery}`;
	showMoreLinkElement.textContent = 'Расширенный поиск';
	showMoreElement.append(showMoreLinkElement);
	searchSuggestionElement.append(listElement, showMoreElement);
	if (searchInputElement.value === '') {
		Array.from(searchSuggestionElement.children)
			.filter((child) => child.nodeType === 1)
			.forEach((child) => child.remove());
		return;
	}
	if (searchInputElement.value.length < MIN_CHARACTERS) {
		Array.from(searchSuggestionElement.children)
			.filter((child) => child.nodeType === 1)
			.forEach((child) => child.remove());
		return;
	}
	searchElement.classList.add('search-form--visible');
	searchClearElement.style.display = 'inline-flex';
	searchClearElement.addEventListener('click', () => {
		clearSearchInput();
		checkSearchInput();
	});
	searchClearElement.addEventListener('click', clearSearchInput);
	document.addEventListener('keydown', (event) => {
		if (event.keyCode === 27) {
			searchElement.classList.remove('search-form--visible');
		}
	});
	document.addEventListener('click', (event) => {
		if (!searchElement.contains(event.target)) {
			searchElement.classList.remove('search-form--visible');
		}
	});
	searchElement.addEventListener('submit', (event) => {
		event.preventDefault();
	});
	searchInputElement.addEventListener('click', () => {
		if (searchInputElement.value !== '') {
			searchElement.classList.add('search-form--visible');
		}
	});
	searchInputElement.addEventListener('input', checkSearchInput);
	searchInputElement.addEventListener('keydown', (event) => {
		const itemCurrentElement = listElement.querySelector('.search-form__suggestion-item--current');
		if (event.keyCode === 38) {
			if (!itemCurrentElement || itemCurrentElement === listElement.firstElementChild) {
				listElement.lastElementChild.classList.add('search-form__suggestion-item--current');
			} else {
				itemCurrentElement.previousElementSibling.classList.add('search-form__suggestion-item--current');
			}
			if (itemCurrentElement) {
				itemCurrentElement.classList.remove('search-form__suggestion-item--current');
				itemCurrentElement.previousElementSibling.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'nearest',
				});
			}
			event.preventDefault();
		} else if (event.keyCode === 40) {
			if (!itemCurrentElement || itemCurrentElement === listElement.lastElementChild) {
				listElement.firstElementChild.classList.add('search-form__suggestion-item--current');
			} else {
				itemCurrentElement.nextElementSibling.classList.add('search-form__suggestion-item--current');
			}
			if (itemCurrentElement) {
				itemCurrentElement.classList.remove('search-form__suggestion-item--current');
				itemCurrentElement.nextElementSibling.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'nearest',
				});
			}
			event.preventDefault();
		} else if (event.keyCode === 13 && itemCurrentElement) {
			const searchSuggestionLink = itemCurrentElement.querySelector('.search-form__suggestion-link');
			if (searchSuggestionLink) {
				window.location.href = searchSuggestionLink.href;
			}
		}
	});
};

export { displaySearchSuggestion };
