﻿const fetchData = async () => {
	// Используем Promise.all для извлечения данных из файлов JSON
	const [categoriesResponse, brandsResponse, productsResponse, subcategoriesResponse] = await Promise.all([
		fetch('../data/categories.json'),
		fetch('../data/manufacturers.json'),
		fetch('../data/products.json'),
		fetch('../data/subcategories.json'),
	]);
	// Преобразуем полученные данные в формат JSON
	const categories = await categoriesResponse.json();
	const manufacturers = await brandsResponse.json();
	const products = await productsResponse.json();
	const subcategories = await subcategoriesResponse.json();
	// Возвращаем полученные данные
	return {
		categories,
		manufacturers,
		products,
		subcategories,
	};
};

export { fetchData };
