﻿import { createBrandCard } from '../constructors/brand-card.js';
import { createBrandGroup } from '../constructors/brand-group.js';
import { createBrandsToc } from '../constructors/brands-toc.js';
import { createProductsGroup } from '../constructors/product-group.js';
import { declineNouns } from '../modules/decline-nouns.js';
import { fetchData } from '../modules/fetch-data.js';
import { toggleBrandView } from '../modules/toggle-view.js';

const titleElement = document.querySelector('title');
const listElement = document.querySelector('#list');
const gridElement = document.querySelector('#grid');
const filterPanelCountElement = document.querySelector('.filter-panel__count');

const groupBrandsByLetter = (manufacturers) => {
	const result = {};
	for (const manufacturer of manufacturers) {
		const firstLetter = manufacturer.name[0].toUpperCase();
		if (!result[firstLetter]) {
			result[firstLetter] = [];
		}
		result[firstLetter].push(manufacturer);
	}
	return result;
};

const createBrandsSectionList = (manufacturersByLetter) => {
	const titleElement = document.createElement('h2');
	titleElement.classList.add('visually-hidden');
	titleElement.textContent = 'Список всех производителей по алфавиту';
	const layoutElement = document.createElement('div');
	layoutElement.classList.add('brands-section__layout');
	layoutElement.id = 'brands';
	const asideElement = document.createElement('aside');
	asideElement.classList.add('brands-section__aside');
	const contentElement = document.createElement('div');
	contentElement.classList.add('brands-section__content');
	for (const [letter, manufacturers] of Object.entries(manufacturersByLetter)) {
		const groupElement = createBrandGroup(letter, manufacturers);
		contentElement.append(groupElement);
	}
	listElement.append(titleElement, asideElement, contentElement);
	const titleElements = document.querySelectorAll('.brand-group__title');
	createBrandsToc(titleElements, asideElement);
};

const createBrandsSectionGrid = (manufacturers) => {
	const titleElement = document.createElement('h2');
	titleElement.classList.add('visually-hidden');
	titleElement.textContent = 'Список всех производителей';
	const listElement = document.createElement('ul');
	listElement.classList.add('brands-section__list');
	for (const manufacturer of manufacturers) {
		const cardElement = createBrandCard(manufacturer);
		listElement.append(cardElement);
	}
	gridElement.append(titleElement, listElement);
};

const countManufacturers = (manufacturers) => {
	if (!filterPanelCountElement) {
		return;
	}
	const count = manufacturers.length;
	if (count === 0) {
		filterPanelCountElement.textContent = 'Производители не найдены';
	} else {
		filterPanelCountElement.textContent = `В каталоге ${count} ${declineNouns(count, ['производитель', 'производителя', 'производителей'])}`;
	}
};

const getManufacturerNames = (manufacturers) => {
	return manufacturers.map((manufacturer) => manufacturer.name);
};

const sortManufacturerNames = (names) => {
	return names.sort();
};

const quoteManufacturerNames = (names) => {
	return names.map((name) => {
		if (/[\u0400-\u04FF]/.test(name)) {
			return `«${name}»`;
		} else {
			return name;
		}
	});
};

const displayFirstAndLastManufacturer = (manufacturers) => {
	const names = getManufacturerNames(manufacturers);
	const sortedNames = sortManufacturerNames(names);
	const quotedNames = quoteManufacturerNames(sortedNames);
	const firstManufacturer = quotedNames[0];
	const lastManufacturer = quotedNames[quotedNames.length - 1];
	titleElement.textContent = `Производители от ${firstManufacturer} до ${lastManufacturer}`;
};

const loadBrands = async () => {
	try {
		const { manufacturers, products } = await fetchData();
		const manufacturersByLetter = groupBrandsByLetter(manufacturers);
		displayFirstAndLastManufacturer(manufacturers);
		countManufacturers(manufacturers);
		createBrandsSectionList(manufacturersByLetter);
		createProductsGroup(manufacturers, products);
		createBrandsSectionGrid(manufacturers);
		toggleBrandView();
	} catch (error) {
		console.error(error);
	}
};

if (window.location.pathname === '/brands') {
	loadBrands();
}
