﻿import { declineNouns } from '../modules/decline-nouns.js';
import { fetchData } from '../modules/fetch-data.js';

const categories = document.querySelector('.categories');
const categoriesCount = document.querySelector('.categories__count');

const countCategories = (categories, subcategories) => {
	if (!categoriesCount) {
		return;
	}
	const categoryCount = categories.length;
	const subcategoryCount = subcategories.length;
	if (categoryCount === 0) {
		categoriesCount.textContent = 'Категории не найдены';
	} else if (subcategoryCount === 0) {
		categoriesCount.textContent = `В каталоге ${categoryCount} ${declineNouns(categoryCount, ['категория', 'категории', 'категорий'])}`;
	} else {
		categoriesCount.textContent = `В каталоге ${categoryCount} ${declineNouns(categoryCount, ['категория', 'категории', 'категорий'])} и ${subcategoryCount} ${declineNouns(categoryCount, [
			'подкатегория',
			'подкатегории',
			'подкатегорий',
		])}`;
	}
};

const createCategoryGroup = (category, subcategories) => {
	const sectionElement = document.createElement('section');
	sectionElement.classList.add('categories__item', 'category-group');
	sectionElement.addEventListener('click', () => {
		window.location.href = `/categories/catalog?category=${category.id}`;
	});
	const titleElement = document.createElement('h3');
	titleElement.classList.add('category-group__title');
	titleElement.textContent = category.name;
	const listElement = document.createElement('ul');
	listElement.classList.add('category-group__list');
	if (subcategories.length === 0) {
		const itemElement = document.createElement('li');
		itemElement.classList.add('category-group__item');
		const textElement = document.createElement('span');
		textElement.classList.add('category-group__text');
		textElement.textContent = 'У этой категории нет подкатегорий';
		itemElement.append(textElement);
		listElement.append(itemElement);
	} else {
		for (const subcategory of subcategories) {
			const itemElement = document.createElement('li');
			itemElement.classList.add('category-group__item');
			const linkElement = document.createElement('a');
			linkElement.classList.add('category-group__link');
			linkElement.href = `/categories/catalog?category=${subcategory.categoryId}&subcategory=${subcategory.id}`;
			const nameElement = document.createElement('span');
			nameElement.textContent = subcategory.name;
			linkElement.append(nameElement);
			itemElement.append(linkElement);
			listElement.append(itemElement);
		}
	}
	sectionElement.append(titleElement, listElement);
	return sectionElement;
};

const createCategoryMap = (categories, subcategories) => {
	const categoryMap = new Map();
	for (const category of categories) {
		const subcategoriesInCategory = subcategories.filter((subcategory) => subcategory.categoryId === category.id);
		const categoryGroup = createCategoryGroup(category, subcategoriesInCategory);
		categoryMap.set(category.id, categoryGroup);
	}
	return categoryMap;
};

const displayCategories = (categoryMap) => {
	for (const categoryGroup of categoryMap.values()) {
		categories.append(categoryGroup);
	}
};

const loadCategories = async () => {
	try {
		const { categories, subcategories } = await fetchData();
		countCategories(categories, subcategories);
		const categoryMap = createCategoryMap(categories, subcategories);
		displayCategories(categoryMap);
	} catch (error) {
		console.error(error);
	}
};

if (window.location.pathname === '/categories') {
	loadCategories();
}
