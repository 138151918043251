﻿import { createBrandCard } from '../constructors/brand-card.js';
import { createProductCard } from '../constructors/product-card.js';
import { fetchData } from '../modules/fetch-data.js';

const MAX_CARDS = 9;
const featureElement = document.querySelector('.feature');
const pageElement = document.querySelector('.page');
const headerLogoLinkElement = document.querySelector('.header__logo-link');

const createFeatureList = (items, createCard, limit) => {
	const listElement = document.createElement('ul');
	listElement.classList.add('feature-section__list');
	const shuffledItems = items.sort(() => 0.5 - Math.random());
	const selectedItems = shuffledItems.slice(0, limit);
	selectedItems.forEach((item) => {
		const cardElement = createCard(item, item.brandId);
		listElement.append(cardElement);
	});
	return listElement;
};

const createFeatureSectionByCategory = (categories, categoryId, products) => {
	const listElement = createFeatureList(
		products.filter((product) => product.categoryId === categoryId),
		(product) => createProductCard(product, product.brandId),
		MAX_CARDS,
	);
	const sectionElement = document.createElement('section');
	sectionElement.classList.add('feature__section', 'feature-section');
	const titleElement = document.createElement('h2');
	titleElement.classList.add('feature-section__title');
	const category = categories.find((c) => c.id === categoryId);
	if (category) {
		titleElement.textContent = category.name;
	}
	sectionElement.append(titleElement, listElement);
	return sectionElement;
};

const createFeatureSectionBySubcategory = (subcategories, subcategoryId, products) => {
	const listElement = createFeatureList(
		products.filter((product) => product.subcategoryId === subcategoryId),
		(product) => createProductCard(product, product.brandId),
		MAX_CARDS,
	);
	const sectionElement = document.createElement('section');
	sectionElement.classList.add('feature__section', 'feature-section');
	const titleElement = document.createElement('h2');
	titleElement.classList.add('feature-section__title');
	const subcategory = subcategories.find((s) => s.id === subcategoryId);
	if (subcategory) {
		titleElement.textContent = subcategory.name;
	}
	sectionElement.append(titleElement, listElement);
	return sectionElement;
};

const createFeatureSection = (title, listElement) => {
	const sectionElement = document.createElement('section');
	sectionElement.classList.add('feature__section', 'feature-section');
	const titleElement = document.createElement('h2');
	titleElement.classList.add('feature-section__title');
	titleElement.textContent = title;
	sectionElement.append(titleElement, listElement);
	return sectionElement;
};

const selectById = (items, ids) => items.filter((item) => ids.includes(item.id));

const loadMain = async () => {
	try {
		const { categories, manufacturers, products, subcategories } = await fetchData();
		const category1SectionElement = createFeatureSectionByCategory(categories, 1, products);
		const subcategory2SectionElement = createFeatureSectionBySubcategory(subcategories, 2, products);
		const category2SectionElement = createFeatureSectionByCategory(categories, 2, products);
		const selectedProducts = selectById(products, [46, 94, 26, 23]);
		const featuredProductsSectionElement = createFeatureSection('Избранные товары', createFeatureList(selectedProducts, createProductCard, MAX_CARDS));
		featuredProductsSectionElement.classList.add('feature-section--background');
		featuredProductsSectionElement.style.backgroundImage = 'url(001.png)';
		const selectedBrands = selectById(manufacturers, [1, 3]);
		const featuredBrandsSectionElement = createFeatureSection('Избранные производители', createFeatureList(selectedBrands, createBrandCard, MAX_CARDS));
		featuredBrandsSectionElement.classList.add('feature-section__list--brands');
		featureElement.append(category1SectionElement, subcategory2SectionElement, category2SectionElement, featuredProductsSectionElement, featuredBrandsSectionElement);
	} catch (error) {
		console.error(error);
	}
};

if (window.location.pathname === '/') {
	if (!pageElement.classList.contains('page--index')) {
		pageElement.classList.add('page--index');
	}
	const arrayOfWords = [
		{
			link: 1,
			name: 'адгезивных систем',
		},
		{
			link: 2,
			name: 'полиэфиров',
		},
	];
	const randomSentence = (arrayOfWords) => {
		let randomElements = [];
		while (randomElements.length < 2) {
			const randomIndex = Math.floor(Math.random() * arrayOfWords.length);
			const randomElement = arrayOfWords[randomIndex];
			if (!randomElements.includes(randomElement)) {
				randomElements.push(randomElement);
			}
		}
		const sentence = `от <a class="intro__title-link" href="/categories/catalog?category=${randomElements[0].link}">${randomElements[0].name}</a> до <a class="intro__title-link" href="/categories/catalog?category=${randomElements[1].link}">${randomElements[1].name}</a>.`;
		return sentence;
	};
	const generateSentence = () => {
		const sentence = randomSentence(arrayOfWords);
		document.querySelector('.intro__title-sentence').innerHTML = sentence;
	};
	generateSentence();
	const headerElement = document.querySelector('.header');
	const introElement = document.querySelector('.intro');
	const introTitleButtonElement = document.querySelector('.intro__title-button');
	const headerHeight = headerElement.offsetHeight;
	const introHeight = introElement.offsetHeight;
	const totalHeight = headerHeight + introHeight;
	const headerLogoLinkElement = document.querySelector('.header__logo-link');
	const setBeforeHeight = (element, totalHeight) => {
		element.style.setProperty('--before-height', `calc(${totalHeight}px + var(--space-lg) - var(--space-sm))`);
	};
	const replaceHeaderLogo = () => {
		headerLogoLinkElement.removeAttribute('href');
		const textElement = document.createElement('span');
		textElement.classList.add('header__logo-text');
		textElement.textContent = headerLogoLinkElement.textContent.trim();
		headerLogoLinkElement.replaceWith(textElement);
	};
	loadMain();
	replaceHeaderLogo();
	const pageIndexElement = document.querySelector('.page--index');
	setBeforeHeight(pageIndexElement, totalHeight);
	window.addEventListener('resize', () => {
		const headerHeight = headerElement.offsetHeight;
		const introHeight = introElement.offsetHeight;
		const totalHeight = headerHeight + introHeight;
		setBeforeHeight(pageIndexElement, totalHeight);
	});
	introTitleButtonElement.addEventListener('click', () => {
		generateSentence();
		const headerHeight = headerElement.offsetHeight;
		const introHeight = introElement.offsetHeight;
		const totalHeight = headerHeight + introHeight;
		setBeforeHeight(pageIndexElement, totalHeight);
	});
	let prevScrollPos = window.pageYOffset;
	headerElement.setAttribute('data-theme', 'dark');
	window.addEventListener('scroll', () => {
		const currentScrollPos = window.pageYOffset;
		if (prevScrollPos > currentScrollPos && currentScrollPos <= headerElement.offsetHeight + introElement.offsetHeight) {
			headerElement.setAttribute('data-theme', 'dark');
		} else {
			headerElement.removeAttribute('data-theme');
		}
		prevScrollPos = currentScrollPos;
	});
	introTitleButtonElement.addEventListener('click', () => {
		introTitleButtonElement.classList.add('intro__title-button--animate');
	});
	introTitleButtonElement.addEventListener(
		'animationend',
		() => {
			introTitleButtonElement.classList.remove('intro__title-button--animate');
		},
		false,
	);
}
