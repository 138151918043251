﻿import { createBrandsCatalogNotFound, createCatalogEmpty } from '../constructors/empty.js';
import { createProductCard } from '../constructors/product-card.js';
import { createProductCardV2 } from '../constructors/product-card-v2.js';
import { createProductDetails } from '../constructors/product-details.js';
import { declineNouns } from '../modules/decline-nouns.js';
import { fetchData } from '../modules/fetch-data.js';
import { quoteText } from '../modules/quote-text.js';
import { truncateText, toggleHiddenText } from '../modules/read-more.js';

const titleElement = document.querySelector('title');
const introBackLinkElement = document.querySelector('.intro__back-link');
const introTitleElement = document.querySelector('.intro__title');
const introDescriptionElement = document.querySelector('.intro__description');
const catalogAsideElement = document.querySelector('.catalog__aside');
const catalogCloseFiltersElement = document.querySelector('.catalog__close-filters');
const catalogContentElement = document.querySelector('.catalog__content');
const catalogListElement = document.querySelector('.catalog__list');
const catalogOpenFiltersElement = document.querySelector('.catalog__open-filters');
const gridViewElement = document.querySelector('#grid-view');
const listViewElement = document.querySelector('#list-view');
const minPriceElement = document.querySelector('#min-price');
const maxPriceElement = document.querySelector('#max-price');
const resetPriceElement = document.querySelector('#reset-price');
const searchProductsElement = document.querySelector('#search-products');
const sortProductsElement = document.querySelector('#sort-products');
let timer;
let currentParams = {
	products: [],
	manufacturerId: null,
	searchQuery: '',
	order: 'asc',
	sortBy: 'name',
	minPrice: null,
	maxPrice: null,
	gridView: true,
	selectedCategories: [],
	selectedSubcategories: [],
};

const handleSearch = () => {
	const { products, manufacturerId, searchQuery } = currentParams;
	const searchWords = searchQuery.toLowerCase().split(' ');
	const filteredProducts = products.filter((product) => {
		const hasMatchingBrand = product.brandId === manufacturerId;
		const hasMatchingValue = Object.values(product).some((value) => {
			if (typeof value === 'string') {
				const lowerCaseValue = value.toLowerCase();
				return searchWords.some((word) => lowerCaseValue.includes(word));
			}
			return false;
		});
		return hasMatchingBrand && hasMatchingValue;
	});
	return filteredProducts;
};

const filterProductsByPrice = (products, minPrice, maxPrice) => {
	if (minPrice === null || maxPrice === null) {
		return products;
	} else {
		return products.filter((product) => {
			return product.price >= minPrice && product.price <= maxPrice;
		});
	}
};

const sortProducts = (products, order, sortBy) => {
	const comparePrice = (a, b) => {
		if (order === 'desc') {
			return b.price - a.price;
		} else {
			return a.price - b.price;
		}
	};
	const compareName = (a, b) => {
		if (order === 'desc') {
			return b.name.localeCompare(a.name);
		} else {
			return a.name.localeCompare(b.name);
		}
	};
	if (sortBy === 'price') {
		return products.sort(comparePrice);
	} else if (sortBy === 'name') {
		return products.sort(compareName);
	}
};

const displayProducts = (products, manufacturerId, gridView) => {
	catalogListElement.innerHTML = '';
	if (products.length === 0) {
		const catalogListEmpty = createCatalogEmpty();
		catalogListElement.append(catalogListEmpty);
	} else {
		products.forEach((product) => {
			let productCard;
			if (gridView) {
				productCard = createProductCard(product, manufacturerId, products, 'catalog__item');
			} else {
				productCard = createProductCardV2(product, manufacturerId, products);
			}
			catalogListElement.append(productCard);
		});
	}
};

const updateProductList = () => {
	const { order, sortBy, manufacturerId, gridView } = currentParams;
	const filteredProducts = handleSearch();
	const priceFilteredProducts = filterProductsByPrice(filteredProducts, currentParams.minPrice, currentParams.maxPrice);
	const sortedProducts = sortProducts(priceFilteredProducts, order, sortBy);
	displayProducts(sortedProducts, manufacturerId, gridView);
	countProducts(sortedProducts);
};

const checkInputValue = (input, minPrice, maxPrice) => {
	const inputValue = parseFloat(input.value);
	if (isNaN(inputValue)) {
		input.value = input === minPriceElement ? minPrice : maxPrice;
	} else if (inputValue.length === 0) {
		input.value = input === minPriceElement ? minPrice : maxPrice;
	} else if (input === minPriceElement && inputValue < minPrice) {
		input.value = minPrice;
	} else if (input === maxPriceElement && inputValue > maxPrice) {
		input.value = maxPrice;
	} else if (input === minPriceElement && inputValue > maxPrice) {
		input.value = maxPrice;
	} else if (input === maxPriceElement && inputValue < minPrice) {
		input.value = minPrice;
	}
};

const handleInputChange = (event, manufacturers) => {
	clearTimeout(timer);
	timer = setTimeout(() => {
		const { manufacturerId, products } = currentParams;
		const manufacturer = manufacturers.find((m) => m.id == manufacturerId);
		const inputElement = event.target;
		const inputValue = parseFloat(inputElement.value);
		const minPrice = Math.min(...products.filter((p) => p.brandId == manufacturer.id).map((p) => p.price));
		const maxPrice = Math.max(...products.filter((p) => p.brandId == manufacturer.id).map((p) => p.price));
		checkInputValue(inputElement, minPrice, maxPrice);
		if (parseFloat(minPriceElement.value) > parseFloat(maxPriceElement.value)) {
			minPriceElement.value = maxPriceElement.value;
		}
		if (parseFloat(maxPriceElement.value) < parseFloat(minPriceElement.value)) {
			maxPriceElement.value = minPriceElement.value;
		}
		resetPriceElement.classList.toggle('visually-hidden', minPriceElement.value == minPrice && maxPriceElement.value == maxPrice);
		if (!resetPriceElement.classList.contains('visually-hidden')) {
			resetPriceElement.classList.add('button', 'button--sm', 'button--subtle');
		}
		currentParams = { ...currentParams, minPrice: parseFloat(minPriceElement.value), maxPrice: parseFloat(maxPriceElement.value) };
		updateProductList();
	}, 1000);
};

const handleResetPrice = (manufacturers) => {
	const { manufacturerId, products } = currentParams;
	const manufacturer = manufacturers.find((m) => m.id == manufacturerId);
	const minPrice = Math.min(...products.filter((p) => p.brandId == manufacturer.id).map((p) => p.price));
	const maxPrice = Math.max(...products.filter((p) => p.brandId == manufacturer.id).map((p) => p.price));
	minPriceElement.value = minPrice;
	maxPriceElement.value = maxPrice;
	resetPriceElement.classList.toggle('visually-hidden', true);
	currentParams = { ...currentParams, minPrice: minPrice, maxPrice: maxPrice };
	updateProductList();
};

const filterProducts = () => {
	const { products, manufacturerId, minPrice, maxPrice } = currentParams;
	const filteredProducts = products.filter((p) => p.price >= minPrice && p.price <= maxPrice && p.brandId == manufacturerId);
	currentParams = { ...currentParams, minPrice: parseFloat(minPriceElement.value), maxPrice: parseFloat(maxPriceElement.value) };
	updateProductList();
};

const showProductDetails = (product, manufacturerId, products) => {
	const modal = createProductDetails(product, manufacturerId, products);
	document.body.append(modal);
	document.addEventListener('keydown', (event) => {
		if (event.keyCode === 27) {
			modal.remove();
		}
	});
};

const createCategoryFilters = (products, categories) => {
	const categoryIds = new Set();
	products.forEach((product) => {
		if (product.categoryId) {
			categoryIds.add(product.categoryId);
		}
	});
	const listElement = document.createElement('ul');
	listElement.classList.add('filter-group__list');
	categoryIds.forEach((categoryId) => {
		const category = categories.find((c) => c.id === categoryId);
		if (category) {
			const itemElement = document.createElement('li');
			itemElement.classList.add('filter-group__item');
			const controlElement = document.createElement('label');
			controlElement.classList.add('filter-group__control', 'checkbox');
			const inputElement = document.createElement('input');
			inputElement.checked = true;
			inputElement.classList.add('checkbox__input');
			inputElement.name = 'category';
			inputElement.type = 'checkbox';
			inputElement.value = category.id;
			const labelElement = document.createElement('span');
			labelElement.classList.add('checkbox__label');
			labelElement.textContent = category.name;
			controlElement.append(inputElement, labelElement);
			itemElement.append(controlElement);
			listElement.append(itemElement);
		}
	});
	return listElement;
};

const createSubcategoryFilters = (products, subcategories) => {
	const subcategoryIds = new Set();
	products.forEach((product) => {
		if (product.subcategoryId) {
			subcategoryIds.add(product.subcategoryId);
		}
	});
	const listElement = document.createElement('ul');
	listElement.classList.add('filter-group__list');
	subcategoryIds.forEach((subcategoryId) => {
		const subcategory = subcategories.find((s) => s.id === subcategoryId);
		if (subcategory) {
			const itemElement = document.createElement('li');
			itemElement.classList.add('filter-group__item');
			const controlElement = document.createElement('label');
			controlElement.classList.add('filter-group__control', 'checkbox');
			const inputElement = document.createElement('input');
			inputElement.checked = true;
			inputElement.classList.add('checkbox__input');
			inputElement.name = 'subcategory';
			inputElement.type = 'checkbox';
			inputElement.value = subcategory.id;
			const labelElement = document.createElement('span');
			labelElement.classList.add('checkbox__label');
			labelElement.textContent = subcategory.name;
			controlElement.append(inputElement, labelElement);
			itemElement.append(controlElement);
			listElement.append(itemElement);
		}
	});
	return listElement;
};

const createCatalogFilters = (products, categories, subcategories) => {
	const filtersElement = document.querySelector('.catalog__filters');
	const categoryGroupElement = document.createElement('div');
	categoryGroupElement.classList.add('filters__item', 'filter-group');
	const categoryTitleElement = document.createElement('h4');
	categoryTitleElement.classList.add('filter-group__title');
	categoryTitleElement.textContent = 'Категории';
	const categoryListElement = createCategoryFilters(products, categories);
	const subcategoryGroupElement = document.createElement('div');
	subcategoryGroupElement.classList.add('filters__item', 'filter-group');
	const subcategoryTitleElement = document.createElement('h4');
	subcategoryTitleElement.classList.add('filter-group__title');
	subcategoryTitleElement.textContent = 'Подкатегории';
	const subcategoryListElement = createSubcategoryFilters(products, subcategories);
	categoryGroupElement.append(categoryTitleElement, categoryListElement);
	subcategoryGroupElement.append(subcategoryTitleElement, subcategoryListElement);
	filtersElement.append(categoryGroupElement, subcategoryGroupElement);
};

const loadBrandsCatalog = async () => {
	try {
		const urlParams = new URLSearchParams(window.location.search);
		let brandId, productId;
		brandId = urlParams.get('brand');
		productId = urlParams.get('product');
		if (productId === '') {
			productId = null;
			urlParams.delete('product');
			window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
		}
		let { categories, manufacturers, products, subcategories } = await fetchData();
		const manufacturer = manufacturers.find((m) => m.id == brandId);
		if (!brandId || !manufacturers.some((m) => m.id == brandId)) {
			window.location.href = '../brands';
			return;
		}
		if (productId && !products.some((p) => p.id == productId && p.brandId == brandId)) {
			window.location.href = `/brands/catalog?brand=${brandId}`;
			return;
		}
		products = products.filter((p) => p.brandId == manufacturer.id).sort((a, b) => a.name.localeCompare(b.name));
		titleElement.textContent = `Страница производителя ${quoteText(manufacturer.name)}`;
		introTitleElement.textContent = manufacturer.name;
		introDescriptionElement.textContent = manufacturer.description;
		truncateText();
		document.addEventListener('click', toggleHiddenText);
		if (products.length === 0) {
			const catalogElement = document.querySelector('.catalog');
			const layoutElement = catalogElement.querySelector('.catalog__layout');
			layoutElement.remove();
			const emptyElement = createBrandsCatalogNotFound();
			catalogElement.append(emptyElement);
			return;
		}
		countProducts(products);
		createCatalogFilters(products, categories, subcategories);
		const prices = products.map((p) => p.price);
		const [minPrice, maxPrice] = [Math.min(...prices), Math.max(...prices)];
		minPriceElement.value = minPrice;
		maxPriceElement.value = maxPrice;
		const manufacturerId = manufacturer?.id ?? null;
		currentParams = { ...currentParams, products, manufacturerId };
		if (productId) {
			const product = products.find((p) => p.id == productId);
			showProductDetails(product, manufacturerId, products);
		}
		updateProductList();
		introBackLinkElement.addEventListener('click', () => {
			window.location.href = '../brands';
		});
		gridViewElement.checked = true;
		gridViewElement.addEventListener('change', () => toggleCatalogView(true));
		listViewElement.addEventListener('change', () => toggleCatalogView(false));
		minPriceElement.addEventListener('input', (event) => {
			event.target.value = event.target.value.replace(/^0/, '');
			handleInputChange(event, manufacturers);
		});
		maxPriceElement.addEventListener('input', (event) => {
			event.target.value = event.target.value.replace(/^0/, '');
			handleInputChange(event, manufacturers);
		});
		resetPriceElement.addEventListener('click', (event) => {
			event.preventDefault();
			handleResetPrice(manufacturers);
		});
		let searchTimeout;
		searchProductsElement.addEventListener('input', () => {
			clearTimeout(searchTimeout);
			searchTimeout = setTimeout(() => {
				const searchQuery = searchProductsElement.value.toLowerCase();
				currentParams = { ...currentParams, searchQuery };
				updateProductList();
			}, 1000);
		});
		searchProductsElement.addEventListener('keydown', (event) => {
			if (event.keyCode === 13) {
				event.preventDefault();
			}
		});
		sortProductsElement.addEventListener('change', (event) => {
			const [sortBy, order] = event.target.value.split('-');
			currentParams = { ...currentParams, sortBy, order };
			updateProductList();
		});
		const categoryCheckboxElements = document.querySelectorAll('.checkbox__input[name="category"]');
		categoryCheckboxElements.forEach((checkbox) => {
			checkbox.addEventListener('change', () => {
				updateFilterOptions();
			});
		});
		const subcategoryCheckboxElements = document.querySelectorAll('.checkbox__input[name="subcategory"]');
		subcategoryCheckboxElements.forEach((checkbox) => {
			checkbox.addEventListener('change', () => {
				updateFilterOptions();
			});
		});
		const updateFilterOptions = () => {
			const selectedCategories = [];
			const selectedSubcategories = [];
			categoryCheckboxElements.forEach((checkbox) => {
				if (checkbox.checked) {
					selectedCategories.push(checkbox.value);
				}
			});
			subcategoryCheckboxElements.forEach((checkbox) => {
				if (checkbox.checked) {
					selectedSubcategories.push(checkbox.value);
				}
			});
			currentParams = { ...currentParams, selectedCategories, selectedSubcategories };
			updateProductList();
		};
		toggleCatalogAsideVisibility();
		toggleCatalogView(true);
	} catch (error) {
		console.error(error);
	}
};

const toggleCatalogAsideVisibility = () => {
	if (catalogOpenFiltersElement) {
		catalogOpenFiltersElement.addEventListener('click', () => catalogAsideElement.classList.add('catalog__aside--visible'));
	}
	if (catalogCloseFiltersElement) {
		catalogCloseFiltersElement.addEventListener('click', () => catalogAsideElement.classList.remove('catalog__aside--visible'));
	}
	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape' && catalogAsideElement.classList.contains('catalog__aside--visible')) {
			catalogAsideElement.classList.remove('catalog__aside--visible');
		}
	});
};

const toggleCatalogView = (gridView) => {
	currentParams = { ...currentParams, gridView };
	updateProductList();
	const catalogList = document.querySelector('.catalog__list');

	catalogList.classList.remove('catalog__list--grid-view');
	catalogList.classList.remove('catalog__list--list-view');

	if (gridView) {
		catalogList.classList.add('catalog__list--grid-view');
	} else {
		catalogList.classList.add('catalog__list--list-view');
	}
};

const countProducts = (products) => {
	const countElement = document.querySelector('.filter-panel__count');
	if (!countElement) {
		return;
	}
	const count = products.length;
	if (count === 0) {
		countElement.textContent = 'Товары не найдены';
	} else {
		countElement.textContent = `В каталоге ${count} ${declineNouns(count, ['товар', 'товара', 'товаров'])}`;
	}
};

const path = window.location.pathname;

if (window.location.pathname.includes('/brands/catalog')) {
	loadBrandsCatalog();
}

export { showProductDetails };
