﻿import { addToCart, countCartItems, createProductCardInput, createProductCardCounter } from './product-card.js';

const createProductDetailsHeader = (product) => {
	const headerElement = document.createElement('header');
	headerElement.classList.add('product-details__header');
	const figureElement = document.createElement('figure');
	figureElement.classList.add('product-details__figure');
	const imageElement = document.createElement('img');
	imageElement.alt = product.name;
	imageElement.classList.add('product-details__image');
	if (!product.image || product.image === '') {
		imageElement.src = '004.jpg';
	} else {
		imageElement.src = product.image;
	}
	figureElement.append(imageElement);
	headerElement.append(figureElement);
	return headerElement;
};

const createProductDetailsContent = (product) => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('product-details__content');
	const nameElement = document.createElement('div');
	nameElement.classList.add('product-details__name');
	const nameCaptionElement = document.createElement('div');
	nameCaptionElement.classList.add('visually-hidden');
	nameCaptionElement.textContent = 'Название';
	const nameTextElement = document.createElement('div');
	nameTextElement.classList.add('product-details__text');
	nameTextElement.textContent = product.name;
	const descriptionElement = document.createElement('div');
	descriptionElement.classList.add('product-details__description');
	const descriptionCaptionElement = document.createElement('div');
	descriptionCaptionElement.classList.add('product-details__caption');
	descriptionCaptionElement.textContent = 'Описание';
	const descriptionTextElement = document.createElement('p');
	descriptionTextElement.classList.add('product-details__text');
	descriptionTextElement.textContent = product.description;
	const priceElement = document.createElement('div');
	priceElement.classList.add('product-details__price');
	const priceCaptionElement = document.createElement('div');
	priceCaptionElement.classList.add('product-details__caption');
	priceCaptionElement.textContent = 'Цена';
	const priceTextElement = document.createElement('div');
	priceTextElement.classList.add('product-details__text');
	priceTextElement.textContent = `${product.price} ₽`;
	nameElement.append(nameCaptionElement, nameTextElement);
	descriptionElement.append(descriptionCaptionElement, descriptionTextElement);
	priceElement.append(priceCaptionElement, priceTextElement);
	contentElement.append(nameElement, descriptionElement, priceElement);
	return contentElement;
};

const createProductDetailsFooter = (product, brandId) => {
	const footerElement = document.createElement('div');
	footerElement.classList.add('product-details__footer');
	const actionsElement = document.createElement('div');
	actionsElement.classList.add('product-details__actions');
	actionsElement.addEventListener('click', (event) => {
		event.preventDefault();
	});
	const buttonElement = document.createElement('button');
	buttonElement.classList.add('product-details__button', 'button', 'button--md', 'button--primary');
	buttonElement.type = 'button';
	const buttonTextElement = document.createElement('span');
	buttonTextElement.classList.add('button__text');
	buttonTextElement.textContent = 'В корзину';
	const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
	const existingItemIndex = cartItems.findIndex((item) => item.productId === product.id);
	if (existingItemIndex !== -1) {
		buttonElement.style.display = 'none';
		const inputElement = createProductCardInput(actionsElement);
		inputElement.value = cartItems[existingItemIndex].quantity;
		const counterElement = createProductCardCounter(actionsElement, inputElement, product, brandId);
	} else {
		buttonElement.addEventListener('click', (event) => {
			event.preventDefault();
			addToCart(product);
			countCartItems();
			buttonElement.style.display = 'none';
			const inputElement = createProductCardInput(actionsElement);
			const counterElement = createProductCardCounter(actionsElement, inputElement, product, brandId);
		});
	}
	buttonElement.append(buttonTextElement);
	actionsElement.append(buttonElement);
	footerElement.append(actionsElement);
	return footerElement;
};

const createProductDetailsClose = (brandId) => {
	const buttonElement = document.createElement('button');
	buttonElement.classList.add('product-details__close', 'button');
	buttonElement.type = 'button';
	buttonElement.innerHTML = '<svg class="product-details__close-icon" height="24" width="24"><use href="#xIcon"></use></svg>';
	buttonElement.addEventListener('click', () => {
		const pageElement = document.querySelector('.page');
		if (pageElement.classList.contains('page--overflow-hidden')) {
			pageElement.classList.remove('page--overflow-hidden');
		}
		const detailsElement = document.querySelector('.product-details');
		detailsElement.remove();
		window.history.pushState(null, null, `/brands/catalog?brand=${brandId}`);
	});
	return buttonElement;
};

const createProductDetailsPrevious = (product, brandId, products) => {
	const buttonElement = document.createElement('button');
	buttonElement.classList.add('product-details__previous', 'button');
	buttonElement.type = 'button';
	buttonElement.innerHTML = '<svg class="product-details__close-icon" height="24" width="24"><use href="#arrowLeftIcon"></use></svg>';
	buttonElement.addEventListener('click', () => {
		const index = products.findIndex((p) => p.id === product.id) - 1;
		if (index >= 0) {
			const previousProduct = products[index];
			const updatedDetailsElement = createProductDetails(previousProduct, brandId, products);
			const detailsElement = document.querySelector('.product-details');
			detailsElement.replaceWith(updatedDetailsElement);
			const previousProductId = previousProduct.id;
			const url = `/brands/catalog?brand=${brandId}&product=${previousProductId}`;
			window.history.pushState(null, null, url);
		}
	});
	return buttonElement;
};

const createProductDetailsNext = (product, brandId, products) => {
	const buttonElement = document.createElement('button');
	buttonElement.classList.add('product-details__next', 'button');
	buttonElement.type = 'button';
	buttonElement.innerHTML = '<svg class="product-details__close-icon" height="24" width="24"><use href="#arrowRightIcon"></use></svg>';
	buttonElement.addEventListener('click', () => {
		const index = products.findIndex((p) => p.id === product.id) + 1;
		if (index < products.length) {
			const nextProduct = products[index];
			const updatedDetailsElement = createProductDetails(nextProduct, brandId, products);
			const detailsElement = document.querySelector('.product-details');
			detailsElement.replaceWith(updatedDetailsElement);
			const nextProductId = nextProduct.id;
			const url = `/brands/catalog?brand=${brandId}&product=${nextProductId}`;
			window.history.pushState(null, null, url);
		}
	});
	return buttonElement;
};

const createProductDetails = (product, brandId, products) => {
	const pageElement = document.querySelector('.page');
	if (!pageElement.classList.contains('page--overflow-hidden')) {
		pageElement.classList.add('page--overflow-hidden');
	}
	const detailsElement = document.createElement('div');
	detailsElement.classList.add('product-details');
	const containerElement = document.createElement('div');
	containerElement.classList.add('product-details__inner');
	const closeElement = createProductDetailsClose(brandId);
	const headerElement = createProductDetailsHeader(product);
	const contentElement = createProductDetailsContent(product);
	const footerElement = createProductDetailsFooter(product);
	const previousElement = createProductDetailsPrevious(product, brandId, products);
	const nextElement = createProductDetailsNext(product, brandId, products);
	const navElement = document.createElement('div');
	navElement.classList.add('product-details__nav');
	navElement.append(previousElement, nextElement);
	containerElement.append(headerElement, contentElement, footerElement);
	detailsElement.append(closeElement, containerElement, navElement);
	document.addEventListener('keydown', (event) => {
		if (event.ctrlKey && event.keyCode === 37) {
			const index = products.findIndex((p) => p.id === product.id) - 1;
			if (index >= 0) {
				const previousProduct = products[index];
				const updatedDetailsElement = createProductDetails(previousProduct, brandId, products);
				detailsElement.replaceWith(updatedDetailsElement);
				const previousProductId = previousProduct.id;
				const url = `/brands/catalog?brand=${brandId}&product=${previousProductId}`;
				window.history.pushState(null, null, url);
			}
		} else if (event.ctrlKey && event.keyCode === 39) {
			const index = products.findIndex((p) => p.id === product.id) + 1;
			if (index < products.length) {
				const nextProduct = products[index];
				const updatedDetailsElement = createProductDetails(nextProduct, brandId, products);
				detailsElement.replaceWith(updatedDetailsElement);
				const nextProductId = nextProduct.id;
				const url = `/brands/catalog?brand=${brandId}&product=${nextProductId}`;
				window.history.pushState(null, null, url);
			}
		}
	});
	return detailsElement;
};

export { createProductDetails };
