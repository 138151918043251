﻿const createBrandGroup = (letter, manufacturers) => {
	const sectionElement = document.createElement('section');
	sectionElement.classList.add('brands-section__item', 'brand-group');
	sectionElement.dataset.letter = letter;
	const titleElement = document.createElement('h3');
	titleElement.classList.add('brand-group__title');
	titleElement.textContent = letter;
	const listElement = document.createElement('ul');
	listElement.classList.add('brand-group__list');
	for (const { id, name } of manufacturers) {
		const itemElement = document.createElement('li');
		itemElement.classList.add('brand-group__item');
		const linkElement = document.createElement('a');
		linkElement.classList.add('brand-group__link');
		linkElement.dataset.brand = name;
		linkElement.href = `/brands/catalog?brand=${id}`;
		const nameElement = document.createElement('span');
		nameElement.textContent = name;
		linkElement.append(nameElement);
		itemElement.append(linkElement);
		listElement.append(itemElement);
	}
	sectionElement.append(titleElement, listElement);
	return sectionElement;
};

export { createBrandGroup };
