﻿const nameInputElement = document.querySelector('.cart__personal-info-name');
const emailInputElement = document.querySelector('input[type="email"]');
const telInputElement = document.querySelector('input[type="tel"]');

const createCartOrderFigure = () => {
	const figureElement = document.createElement('figure');
	figureElement.classList.add('cart__order-figure');
	const imageElement = document.createElement('img');
	imageElement.alt = '';
	imageElement.classList.add('cart__order-image');
	imageElement.src = '004.jpg';
	figureElement.append(imageElement);
	return figureElement;
};

const createCartOrderTitle = () => {
	const titleElement = document.createElement('div');
	titleElement.classList.add('cart__order-title');
	titleElement.textContent = 'Заказ оформлен!';
	return titleElement;
};

const createCartOrderText = () => {
	const textElement = document.createElement('div');
	textElement.classList.add('cart__order-text');
	if (emailInputElement.value && telInputElement.value) {
		textElement.innerHTML = `Отправим письмо на адрес <span>${emailInputElement.value}</span> и СМС на номер <span>${telInputElement.value}</span>, как только заказ будет готов к получению`;
	} else if (emailInputElement.value) {
		textElement.innerHTML = `Отправим письмо на адрес <span>${emailInputElement.value}</span>, как только заказ будет готов к получению`;
	} else if (telInputElement.value) {
		textElement.innerHTML = `Отправим СМС на номер <span>${telInputElement.value}</span>, как только заказ будет готов к получению`;
	}
	return textElement;
};

const createCartOrderRecipient = () => {
	const recipientElement = document.createElement('div');
	recipientElement.classList.add('cart__order-recipient');
	recipientElement.textContent = `Получатель: ${nameInputElement.value}`;
	return recipientElement;
};

const createCartOrderDeliveryDate = () => {
	const deliveryDateElement = document.createElement('p');
	deliveryDateElement.classList.add('cart__order-delivery-date');
	const date = new Date();
	const year = date.getFullYear();
	const formattedDate = date.toLocaleDateString('ru-RU', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
	date.setDate(date.getDate() + 7);
	if (year === new Date().getFullYear()) {
		const dateWithoutYear = formattedDate.split(' ').slice(0, -2).join(' ');
		deliveryDateElement.textContent = `Примерная дата доставки: ${dateWithoutYear}`;
	} else {
		const dateWithoutYear = formattedDate.split(' ').slice(0, -1).join(' ');
		deliveryDateElement.textContent = `Примерная дата доставки: ${dateWithoutYear}`;
	}
	return deliveryDateElement;
};

const createCartOrderButton = () => {
	const buttonElement = document.createElement('button');
	buttonElement.classList.add('cart__order-button', 'button', 'button--lg', 'button--primary');
	buttonElement.type = 'button';
	const buttonTextElement = document.createElement('span');
	buttonTextElement.classList.add('button__text');
	buttonTextElement.textContent = 'Отменить заказ';
	buttonElement.append(buttonTextElement);
	return buttonElement;
};

const createCartOrderLink = () => {
	const linkElement = document.createElement('a');
	linkElement.href = 'index.html';
	linkElement.textContent = 'На главную';
	return linkElement;
};

const createCartOrder = () => {
	const orderElement = document.createElement('div');
	orderElement.classList.add('cart__order');
	const figureElement = createCartOrderFigure();
	const titleElement = createCartOrderTitle();
	const textElement = createCartOrderText();
	const recipientElement = createCartOrderRecipient();
	const deliveryDateElement = createCartOrderDeliveryDate();
	const buttonElement = createCartOrderButton();
	const linkElement = createCartOrderLink();
	orderElement.append(figureElement, titleElement, textElement, recipientElement, deliveryDateElement, buttonElement, linkElement);
	return orderElement;
};

export { createCartOrder };
