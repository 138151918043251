﻿const createBrandsCatalogNotFound = () => {
	const emptyElement = document.createElement('div');
	emptyElement.classList.add('catalog__empty', 'empty');
	const textElement = document.createElement('div');
	textElement.classList.add('empty__text');
	textElement.textContent = 'У этого производителя нет товаров';
	const linkElement = document.createElement('a');
	linkElement.classList.add('empty__link');
	linkElement.href = 'index.html';
	linkElement.textContent = 'На главную';
	emptyElement.append(textElement, linkElement);
	return emptyElement;
};

const createCartEmpty = () => {
	const emptyElement = document.createElement('div');
	emptyElement.classList.add('cart__empty', 'empty');
	const textElement = document.createElement('div');
	textElement.classList.add('empty__text');
	textElement.textContent = 'Вы еще не добавили товары в корзину';
	const linkElement = document.createElement('a');
	linkElement.classList.add('empty__link');
	linkElement.href = 'brands.html';
	linkElement.textContent = 'Перейти в каталог';
	emptyElement.append(textElement, linkElement);
	return emptyElement;
};

const createCatalogEmpty = () => {
	const emptyElement = document.createElement('li');
	emptyElement.classList.add('catalog__item', 'empty');
	const textElement = document.createElement('div');
	textElement.classList.add('empty__text');
	textElement.textContent = 'Ничего не найдено';
	emptyElement.append(textElement);
	return emptyElement;
};

const createCategoriesCatalogNotFound = () => {
	const emptyElement = document.createElement('div');
	emptyElement.classList.add('catalog__empty', 'empty');
	const textElement = document.createElement('div');
	textElement.classList.add('empty__text');
	textElement.textContent = 'У этой категории нет товаров';
	const linkElement = document.createElement('a');
	linkElement.classList.add('empty__link');
	linkElement.href = 'index.html';
	linkElement.textContent = 'На главную';
	emptyElement.append(textElement, linkElement);
	return emptyElement;
};

export { createBrandsCatalogNotFound, createCartEmpty, createCatalogEmpty, createCategoriesCatalogNotFound };
