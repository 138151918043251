﻿import { showProductDetails } from '../pages/brands-catalog.js';
import { splitIntegers } from '../modules/split-integers.js';
import { addToCart, countCartItems, createProductCardInput, createProductCardCounter } from './product-card.js';

const createProductCardV2Link = (product, brandId, products) => {
	const linkElement = document.createElement('a');
	linkElement.classList.add('product-card-v2__link');
	linkElement.href = `/brands/catalog?brand=${brandId}&product=${product.id}`;
	linkElement.addEventListener('click', (event) => {
		if (window.location.pathname.includes('/brands/catalog') && !isInsideCardButton(event.target) && !isInsideCardCounter(event.target)) {
			event.preventDefault();
			showProductDetails(product, brandId, products);
			window.history.pushState(null, null, linkElement.href);
		} else {
			return;
		}
	});
	const isInsideCardButton = (element) => {
		return element.closest('.product-card__button') !== null;
	};
	const isInsideCardCounter = (element) => {
		return element.closest('.product-card__counter') !== null;
	};
	return linkElement;
};

const createProductCardV2Figure = (product) => {
	const figureElement = document.createElement('figure');
	figureElement.classList.add('product-card-v2__figure');
	const imageElement = document.createElement('img');
	imageElement.alt = product.name;
	imageElement.classList.add('product-card-v2__image');
	if (!product.image || product.image === '') {
		imageElement.src = '004.jpg';
	} else {
		imageElement.src = product.image;
	}
	figureElement.append(imageElement);
	return figureElement;
};

const createProductCardV2Name = (product) => {
	const nameElement = document.createElement('div');
	nameElement.classList.add('product-card-v2__name');
	nameElement.textContent = product.name;
	return nameElement;
};

const createProductCardV2Price = (product) => {
	const priceElement = document.createElement('div');
	priceElement.classList.add('product-card-v2__price');
	priceElement.textContent = `${splitIntegers(product.price)} ₽`;
	return priceElement;
};

const createProductCardV2Actions = (product, brandId) => {
	const actionsElement = document.createElement('div');
	actionsElement.classList.add('product-card-v2__actions');
	actionsElement.addEventListener('click', (event) => {
		event.preventDefault();
	});
	const buttonElement = document.createElement('button');
	buttonElement.classList.add('product-card-v2__button', 'button', 'button--md', 'button--primary');
	buttonElement.type = 'button';
	const buttonTextElement = document.createElement('span');
	buttonTextElement.classList.add('button__text');
	buttonTextElement.textContent = 'В корзину';
	const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
	const existingItemIndex = cartItems.findIndex((item) => item.productId === product.id);
	if (existingItemIndex !== -1) {
		buttonElement.style.display = 'none';
		const inputElement = createProductCardInput(actionsElement);
		inputElement.value = cartItems[existingItemIndex].quantity;
		const counterElement = createProductCardCounter(actionsElement, inputElement, product, brandId);
	} else {
		buttonElement.addEventListener('click', (event) => {
			event.preventDefault();
			addToCart(product);
			countCartItems();
			buttonElement.style.display = 'none';
			const inputElement = createProductCardInput(actionsElement);
			const counterElement = createProductCardCounter(actionsElement, inputElement, product, brandId);
		});
	}
	buttonElement.append(buttonTextElement);
	actionsElement.append(buttonElement);
	return actionsElement;
};

const createProductCardV2 = (product, brandId, products) => {
	const cardElement = document.createElement('li');
	cardElement.classList.add('product-card-v2');
	const linkElement = createProductCardV2Link(product, brandId, products);
	const figureElement = createProductCardV2Figure(product);
	const nameElement = createProductCardV2Name(product);
	const priceElement = createProductCardV2Price(product);
	const actionsElement = createProductCardV2Actions(product, brandId);
	linkElement.append(figureElement, nameElement, priceElement, actionsElement);
	cardElement.append(linkElement);
	return cardElement;
};

export { createProductCardV2 };
