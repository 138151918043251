﻿const createBrandCard = (manufacturer) => {
	const cardElement = document.createElement('li');
	cardElement.classList.add('brands-section__item', 'brand-card');
	const linkElement = document.createElement('a');
	linkElement.classList.add('brand-card__link');
	linkElement.href = `/brands/catalog?brand=${manufacturer.id}`;
	const figureElement = document.createElement('figure');
	figureElement.classList.add('brand-card__figure');
	const imageElement = document.createElement('img');
	imageElement.alt = manufacturer.name;
	imageElement.classList.add('brand-card__image');
	imageElement.src = manufacturer.image;
	const nameElement = document.createElement('div');
	nameElement.classList.add('visually-hidden');
	const nameTextElement = document.createElement('span');
	nameTextElement.textContent = manufacturer.name;
	figureElement.append(imageElement);
	nameElement.append(nameTextElement);
	linkElement.append(figureElement, nameElement);
	cardElement.append(linkElement);
	return cardElement;
};

export { createBrandCard };
