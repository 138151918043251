﻿// Создаем список товаров для каждого производителя
const MAX_LINKS = 3;

const createProductsGroupLink = (brandId, product, showMore) => {
	const linkElement = document.createElement('a');
	linkElement.classList.add('product-group__link');
	if (showMore) {
		linkElement.href = `/brands/catalog?brand=${brandId}`;
		linkElement.textContent = 'Показать больше';
	} else {
		linkElement.href = `/brands/catalog?brand=${brandId}&product=${product.id}`;
		linkElement.textContent = product.name;
	}
	return linkElement;
};

const createProductsGroupItem = (linkElement, showMore) => {
	const itemElement = document.createElement('li');
	itemElement.classList.add('product-group__item');
	if (showMore) {
		itemElement.classList.add('product-group__item--show-more');
	}
	itemElement.append(linkElement);
	return itemElement;
};

/*const createProductsGroupList = (brandId, products) => {
	const listElement = document.createElement('ul');
	listElement.classList.add('product-group__list');
	for (const product of products.slice(0, MAX_LINKS)) {
		const linkElement = createProductsGroupLink(brandId, product, false);
		const itemElement = createProductsGroupItem(linkElement, false);
		listElement.append(itemElement);
	}
	if (products.length > MAX_LINKS) {
		const linkElement = createProductsGroupLink(brandId, null, true);
		const itemElement = createProductsGroupItem(linkElement, true);
		listElement.append(itemElement);
	}
	return listElement;
};*/

const createProductsGroupList = (brandId, products) => {
	// Делаем список товаров случайным
	for (let i = products.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[products[i], products[j]] = [products[j], products[i]];
	}
	const listElement = document.createElement('ul');
	listElement.classList.add('product-group__list');
	for (const product of products.slice(0, MAX_LINKS)) {
		const linkElement = createProductsGroupLink(brandId, product, false);
		const itemElement = createProductsGroupItem(linkElement, false);
		listElement.append(itemElement);
	}
	if (products.length > MAX_LINKS) {
		const linkElement = createProductsGroupLink(brandId, null, true);
		const itemElement = createProductsGroupItem(linkElement, true);
		listElement.append(itemElement);
	}
	return listElement;
};

const createProductsGroup = (brands, products) => {
	const brandLinkElements = document.querySelectorAll('.brand-group__link');
	for (const brandLinkElement of brandLinkElements) {
		const brandName = brandLinkElement.dataset.brand;
		const brand = brands.find((brand) => brand.name === brandName);
		const brandProducts = products.filter((product) => product.brandId === brand?.id) || [];
		if (brand && brandProducts.length > 0) {
			const listElement = createProductsGroupList(brand.id, brandProducts);
			const groupElement = document.createElement('div');
			groupElement.classList.add('product-group');
			groupElement.append(listElement);
			brandLinkElement.parentNode.append(groupElement);
		}
	}
};

export { createProductsGroup };
