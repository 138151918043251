﻿import { displaySearchResults, search } from '../pages/search.js';
import { displaySearchSuggestion } from '../modules/search-suggestion.js';

const searchResults = [];
let manufacturers, products;

const initSearch = () => {
	const searchInputElement = document.querySelector('.search-form__control-input');
	if (!searchInputElement) {
		return;
	}
	const MIN_CHARACTERS = 1;
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const searchQuery = urlParams.get('query');
	if (searchQuery) {
		searchInputElement.value = searchQuery;
		const searchResults = search(searchQuery, manufacturers, products);
		displaySearchResults(searchQuery, searchResults);
	}
	searchInputElement.addEventListener('input', () => {
		const searchQuery = searchInputElement.value.toLowerCase();
		if (searchQuery.length >= MIN_CHARACTERS) {
			if (window.location.pathname.includes('/search')) {
				const newUrl = new URL(window.location.href);
				newUrl.searchParams.set('query', searchQuery);
				history.pushState(null, null, newUrl.toString());
				const searchResults = search(searchQuery, manufacturers, products);
				displaySearchResults(searchQuery, searchResults);
			} else {
				const searchResults = search(searchQuery, manufacturers, products);
				displaySearchSuggestion(searchResults);
			}
		} else {
			displaySearchSuggestion([]);
			if (window.location.pathname.includes('/search')) {
				history.pushState(null, null, window.location.pathname);
				displaySearchResults(searchQuery, []);
			}
		}
	});
	searchInputElement.addEventListener('keydown', (event) => {
		const searchQuery = searchInputElement.value.toLowerCase();
		if (event.key === 'Enter' && searchQuery.length >= MIN_CHARACTERS) {
			if (window.location.pathname.includes('/search') && (window.location.search === '' || window.location.search.includes('query='))) {
				const newUrl = new URL(window.location.href);
				newUrl.searchParams.set('query', searchQuery);
				history.pushState(null, null, newUrl.toString());
				const searchResults = search(searchQuery, manufacturers, products);
				displaySearchResults(searchQuery, searchResults);
			} else {
				const searchResults = search(searchQuery, manufacturers, products);
				displaySearchSuggestion(searchResults);
			}
		}
	});
};

const quickSearch = async () => {
	try {
		const [brandsResponse, productsResponse] = await Promise.all([fetch('../data/manufacturers.json'), fetch('../data/products.json')]);
		manufacturers = await brandsResponse.json();
		products = await productsResponse.json();
		initSearch();
	} catch (error) {
		console.error(error);
	}
};

quickSearch();
