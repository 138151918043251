﻿import { calculateTotalCost, groupCartItems, updateCartTotal } from '../pages/cart.js';
import { splitIntegers } from '../modules/split-integers.js';

const removeCartItem = (productId) => {
	const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
	const existingItemIndex = cartItems.findIndex((item) => item.productId === productId);
	if (existingItemIndex !== -1) {
		cartItems.splice(existingItemIndex, 1);
		localStorage.setItem('cart', JSON.stringify(cartItems));
		const cardElement = event.target.closest('.added-product-card');
		cardElement.remove();
	}
};

const createAddedProductCard = (product) => {
	const cardElement = document.createElement('li');
	cardElement.classList.add('cart__added-products-item', 'added-product-card', 'added-product-card--hover');
	const quantityElement = document.createElement('div');
	quantityElement.classList.add('added-product-card__quantity');
	quantityElement.textContent = `${product.quantity}x`;
	const linkElement = document.createElement('a');
	linkElement.classList.add('added-product-card__link');
	linkElement.href = `/brands/catalog?brand=${product.brandId}&product=${product.productId}`;
	linkElement.textContent = `${product.name}`;
	const nameElement = document.createElement('div');
	nameElement.classList.add('added-product-card__name');
	const priceElement = document.createElement('div');
	priceElement.classList.add('added-product-card__price');
	priceElement.textContent = `${splitIntegers(product.price * product.quantity)} ₽`;
	const removeElement = document.createElement('div');
	removeElement.classList.add('added-product-card__remove');
	const removeButtonElement = document.createElement('button');
	removeButtonElement.classList.add('added-product-card__remove-button', 'button');
	removeButtonElement.type = 'button';
	removeButtonElement.innerHTML = '<svg class="added-product-card__remove-icon" height="24" width="24"><use href="#xIcon"></use></svg>';
	removeButtonElement.addEventListener('click', () => {
		removeCartItem(product.productId);
		updateCartTotal();
	});
	nameElement.append(linkElement);
	removeElement.append(removeButtonElement);
	cardElement.append(quantityElement, nameElement, priceElement, removeElement);
	return cardElement;
};

export { createAddedProductCard };
