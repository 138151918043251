﻿import { createCategoriesCatalogNotFound, createCatalogEmpty } from '../constructors/empty.js';
import { createProductCard } from '../constructors/product-card.js';
import { createProductCardV2 } from '../constructors/product-card-v2.js';
import { createProductDetails } from '../constructors/product-details.js';
import { declineNouns } from '../modules/decline-nouns.js';
import { fetchData } from '../modules/fetch-data.js';

const titleElement = document.querySelector('title');
const introBackLinkElement = document.querySelector('.intro__back-link');
const introTitleElement = document.querySelector('.intro__title');
const catalogAsideElement = document.querySelector('.catalog__aside');
const catalogCloseFiltersElement = document.querySelector('.catalog__close-filters');
const catalogContentElement = document.querySelector('.catalog__content');
const catalogListElement = document.querySelector('.catalog__list');
const catalogOpenFiltersElement = document.querySelector('.catalog__open-filters');
const gridViewElement = document.querySelector('#grid-view');
const listViewElement = document.querySelector('#list-view');
const minPriceElement = document.querySelector('#min-price');
const maxPriceElement = document.querySelector('#max-price');
const resetPriceElement = document.querySelector('#reset-price');
const searchProductsElement = document.querySelector('#search-products');
const sortProductsElement = document.querySelector('#sort-products');
let timer;
let currentParams = {
	products: [],
	searchQuery: '',
	order: 'asc',
	sortBy: 'name',
	minPrice: null,
	maxPrice: null,
	gridView: true,
};

const handleSearch = () => {
	const { products, searchQuery } = currentParams;
	const searchWords = searchQuery.toLowerCase().split(' ');
	const filteredProducts = products.filter((product) => {
		const hasMatchingValue = Object.values(product).some((value) => {
			if (typeof value === 'string') {
				const lowerCaseValue = value.toLowerCase();
				return searchWords.some((word) => lowerCaseValue.includes(word));
			}
			return false;
		});
		return hasMatchingValue;
	});
	return filteredProducts;
};

const filterProductsByPrice = (products, minPrice, maxPrice) => {
	if (minPrice === null || maxPrice === null) {
		return products;
	} else {
		return products.filter((product) => {
			return product.price >= minPrice && product.price <= maxPrice;
		});
	}
};

const sortProducts = (products, order, sortBy) => {
	const comparePrice = (a, b) => {
		if (order === 'desc') {
			return b.price - a.price;
		} else {
			return a.price - b.price;
		}
	};
	const compareName = (a, b) => {
		if (order === 'desc') {
			return b.name.localeCompare(a.name);
		} else {
			return a.name.localeCompare(b.name);
		}
	};
	if (sortBy === 'price') {
		return products.sort(comparePrice);
	} else if (sortBy === 'name') {
		return products.sort(compareName);
	}
};

const displayProducts = (products, gridView) => {
	catalogListElement.innerHTML = '';
	if (products.length === 0) {
		const catalogListEmpty = createCatalogEmpty();
		catalogListElement.append(catalogListEmpty);
	} else {
		products.forEach((product) => {
			let productCard;
			if (gridView) {
				productCard = createProductCard(product, product.brandId, products, 'catalog__item');
			} else {
				productCard = createProductCardV2(product, product.brandId, products);
			}
			catalogListElement.append(productCard);
		});
	}
};

const updateProductList = () => {
	const { order, sortBy, gridView } = currentParams;
	const filteredProducts = handleSearch();
	const priceFilteredProducts = filterProductsByPrice(filteredProducts, currentParams.minPrice, currentParams.maxPrice);
	const sortedProducts = sortProducts(priceFilteredProducts, order, sortBy);
	displayProducts(sortedProducts, gridView);
	countProducts(sortedProducts);
};

const checkInputValue = (input, minPrice, maxPrice) => {
	const inputValue = parseFloat(input.value);
	if (isNaN(inputValue)) {
		input.value = input === minPriceElement ? minPrice : maxPrice;
	} else if (inputValue.length === 0) {
		input.value = input === minPriceElement ? minPrice : maxPrice;
	} else if (input === minPriceElement && inputValue < minPrice) {
		input.value = minPrice;
	} else if (input === maxPriceElement && inputValue > maxPrice) {
		input.value = maxPrice;
	} else if (input === minPriceElement && inputValue > maxPrice) {
		input.value = maxPrice;
	} else if (input === maxPriceElement && inputValue < minPrice) {
		input.value = minPrice;
	}
};

const handleInputChange = (event) => {
	clearTimeout(timer);
	timer = setTimeout(() => {
		const { products } = currentParams;
		const input = event.target;
		const inputValue = parseFloat(input.value);
		const minPrice = Math.min(...products.map((p) => p.price));
		const maxPrice = Math.max(...products.map((p) => p.price));
		checkInputValue(input, minPrice, maxPrice);
		if (parseFloat(minPriceElement.value) > parseFloat(maxPriceElement.value)) {
			minPriceElement.value = maxPriceElement.value;
		}
		if (parseFloat(maxPriceElement.value) < parseFloat(minPriceElement.value)) {
			maxPriceElement.value = minPriceElement.value;
		}
		resetPriceElement.classList.toggle('visually-hidden', minPriceElement.value === minPrice && maxPriceElement.value === maxPrice);
		if (!resetPriceElement.classList.contains('visually-hidden')) {
			resetPriceElement.classList.add('button', 'button--sm', 'button--subtle');
		}
		currentParams = { ...currentParams, minPrice: parseFloat(minPriceElement.value), maxPrice: parseFloat(maxPriceElement.value) };
		updateProductList();
	}, 1000);
};

const handleResetPrice = () => {
	const { products } = currentParams;
	const minPrice = Math.min(...products.map((p) => p.price));
	const maxPrice = Math.max(...products.map((p) => p.price));
	minPriceElement.value = minPrice;
	maxPriceElement.value = maxPrice;
	resetPriceElement.classList.toggle('visually-hidden', true);
	currentParams = { ...currentParams, minPrice: minPrice, maxPrice: maxPrice };
	updateProductList();
};

const filterProducts = () => {
	const { products, minPrice, maxPrice } = currentParams;
	const filteredProducts = products.filter((p) => p.price >= minPrice && p.price <= maxPrice);
	currentParams = { ...currentParams, minPrice: parseFloat(minPriceElement.value), maxPrice: parseFloat(maxPriceElement.value) };
	updateProductList();
};

const createCatalogFilters = (products, manufacturers) => {
	const filtersElement = document.querySelector('.catalog__filters');
	const groupElement = document.createElement('div');
	groupElement.classList.add('filters__item', 'filter-group');
	const titleElement = document.createElement('h4');
	titleElement.classList.add('filter-group__title');
	titleElement.textContent = 'Производители';
	const listElement = document.createElement('ul');
	listElement.classList.add('filter-group__list');
	const brandIds = new Set();
	products.forEach((product) => {
		if (product.brandId) {
			brandIds.add(product.brandId);
		}
	});
	if (brandIds.size === 0) {
		return;
	}
	const sortedBrands = Array.from(brandIds).sort();
	sortedBrands.forEach((brandId) => {
		const manufacturer = manufacturers.find((m) => m.id == brandId);
		if (manufacturer) {
			const itemElement = document.createElement('li');
			itemElement.classList.add('filter-group__item');
			const controlElement = document.createElement('label');
			controlElement.classList.add('filter-group__control', 'checkbox');
			const inputElement = document.createElement('input');
			inputElement.checked = true;
			inputElement.classList.add('checkbox__input');
			inputElement.name = 'manufacturer';
			inputElement.type = 'checkbox';
			inputElement.value = manufacturer.id;
			const labelElement = document.createElement('span');
			labelElement.classList.add('checkbox__label');
			labelElement.textContent = manufacturer.name;
			controlElement.append(inputElement, labelElement);
			itemElement.append(controlElement);
			listElement.append(itemElement);
		}
	});
	groupElement.append(titleElement, listElement);
	filtersElement.append(groupElement);
};

const loadCategoriesCatalog = async () => {
	try {
		const urlParams = new URLSearchParams(window.location.search);
		let categoryId, subcategoryId;
		categoryId = urlParams.get('category');
		subcategoryId = urlParams.get('subcategory');
		if (categoryId === '') {
			categoryId = null;
			urlParams.delete('product');
			window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
		}
		let { categories, manufacturers, products, subcategories } = await fetchData();
		const category = categories.find((c) => c.id == categoryId);
		const subcategory = subcategories.find((s) => s.id == subcategoryId);
		if (!categoryId || !categories.some((m) => m.id == categoryId)) {
			window.location.href = '../categories';
			return;
		}
		titleElement.textContent = subcategory ? subcategory.name : category.name;
		introTitleElement.textContent = subcategory ? subcategory.name : category.name;
		products = products
			.filter((p) => {
				const productCategory = categories.find((c) => c.id == p.categoryId);
				const productSubcategory = subcategories.find((s) => s.id == p.subcategoryId);
				return (subcategory && subcategory.id == productSubcategory?.id) || (!subcategory && productSubcategory && productCategory?.id == categoryId);
			})
			.sort((a, b) => a.name.localeCompare(b.name));
		if (products.length === 0) {
			const catalogElement = document.querySelector('.catalog');
			const layoutElement = catalogElement.querySelector('.catalog__layout');
			layoutElement.remove();
			const emptyElement = createCategoriesCatalogNotFound();
			catalogElement.append(emptyElement);
			return;
		}
		countProducts(products);
		createCatalogFilters(products, manufacturers);
		const prices = products.map((p) => p.price);
		const [minPrice, maxPrice] = [Math.min(...prices), Math.max(...prices)];
		minPriceElement.value = minPrice;
		maxPriceElement.value = maxPrice;
		currentParams = { ...currentParams, products };
		updateProductList();
		gridViewElement.checked = true;
		gridViewElement.addEventListener('change', () => toggleCatalogView(true));
		listViewElement.addEventListener('change', () => toggleCatalogView(false));
		minPriceElement.addEventListener('input', (event) => {
			event.target.value = event.target.value.replace(/^0/, '');
			handleInputChange(event);
		});
		maxPriceElement.addEventListener('input', (event) => {
			event.target.value = event.target.value.replace(/^0/, '');
			handleInputChange(event);
		});
		resetPriceElement.addEventListener('click', (event) => {
			event.preventDefault();
			handleResetPrice();
		});
		let searchTimeout;
		searchProductsElement.addEventListener('input', () => {
			clearTimeout(searchTimeout);
			searchTimeout = setTimeout(() => {
				const searchQuery = searchProductsElement.value.toLowerCase();
				currentParams = { ...currentParams, searchQuery };
				updateProductList();
			}, 1000);
		});
		searchProductsElement.addEventListener('keydown', (event) => {
			if (event.keyCode === 13) {
				event.preventDefault();
			}
		});
		sortProductsElement.addEventListener('change', (event) => {
			const [sortBy, order] = event.target.value.split('-');
			currentParams = { ...currentParams, sortBy, order };
			updateProductList();
		});
		toggleCatalogAsideVisibility();
		toggleCatalogView(true);
	} catch (error) {
		console.error(error);
	}
};

const toggleCatalogAsideVisibility = () => {
	if (catalogOpenFiltersElement) {
		catalogOpenFiltersElement.addEventListener('click', () => catalogAsideElement.classList.add('catalog__aside--visible'));
	}
	if (catalogCloseFiltersElement) {
		catalogCloseFiltersElement.addEventListener('click', () => catalogAsideElement.classList.remove('catalog__aside--visible'));
	}
	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape' && catalogAsideElement.classList.contains('catalog__aside--visible')) {
			catalogAsideElement.classList.remove('catalog__aside--visible');
		}
	});
};

const toggleCatalogView = (gridView) => {
	currentParams = { ...currentParams, gridView };
	updateProductList();
	const catalogList = document.querySelector('.catalog__list');
	catalogList.classList.remove('catalog__list--grid-view');
	catalogList.classList.remove('catalog__list--list-view');
	if (gridView) {
		catalogList.classList.add('catalog__list--grid-view');
	} else {
		catalogList.classList.add('catalog__list--list-view');
	}
};

const countProducts = (products) => {
	const countElement = document.querySelector('.filter-panel__count');
	if (!countElement) {
		return;
	}
	const count = products.length;
	if (count === 0) {
		countElement.textContent = 'Товары не найдены';
	} else {
		countElement.textContent = `В каталоге ${count} ${declineNouns(count, ['товар', 'товара', 'товаров'])}`;
	}
};

const path = window.location.pathname;

if (window.location.pathname.includes('/categories/catalog')) {
	loadCategoriesCatalog();
}
