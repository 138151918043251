﻿import { debounce } from '../libs/debounce.js';

const views = {
	LIST: 'list',
	GRID: 'grid',
};
const VIEW_PARAM_NAME = 'view';
const DEBOUNCE_DELAY = 250;
let brandsElement = document.querySelector('.brands');
let filterPanelElement;
let sectionElements;
let currentView;
let urlSearchParams = new URLSearchParams(window.location.search);

const applyView = (currentView, sectionElements) => {
	for (const sectionElement of sectionElements) {
		const isCurrentView = sectionElement.id === currentView;
		sectionElement.hidden = !isCurrentView;
		const radioElement = filterPanelElement.querySelector(`[value="${sectionElement.id}"]`);
		if (radioElement) {
			radioElement.checked = isCurrentView;
		}
	}
};

const setUrlSearchParams = (currentView) => {
	urlSearchParams.set(VIEW_PARAM_NAME, currentView);
	const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}${window.location.hash}`;
	history.replaceState(null, null, newUrl);
};

const scrollToSection = () => {
	const hash = window.location.hash;
	if (hash) {
		const brandsSection = document.querySelector(hash);
		if (brandsSection) {
			brandsSection.scrollIntoView();
		}
	}
};

const handleViewChange = (event) => {
	const newView = event.target.value;
	if (!newView) {
		return;
	}
	applyView(newView, sectionElements);
	setUrlSearchParams(newView);
};

const toggleBrandView = () => {
	if (!brandsElement) {
		return;
	}
	filterPanelElement = brandsElement.querySelector('.brands__filter-panel');
	sectionElements = brandsElement.querySelectorAll('.brands__section');
	currentView = urlSearchParams.get(VIEW_PARAM_NAME) || views.LIST;
	applyView(currentView, sectionElements);
	scrollToSection();
	filterPanelElement.addEventListener('change', debounce(handleViewChange, DEBOUNCE_DELAY));
};

export { toggleBrandView };
