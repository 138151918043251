﻿import { createAddedProductCard } from '../constructors/added-product.js';
import { createCartEmpty } from '../constructors/empty.js';
import { createCartOrder } from '../constructors/cart-order.js';
import { declineNouns } from '../modules/decline-nouns.js';
import { splitIntegers } from '../modules/split-integers.js';

const cartElement = document.querySelector('.cart');
const cartLayoutElement = document.querySelector('.cart__layout');
const cartAddedProductsElement = document.querySelector('.cart__added-products');
const cartTotalButtonElement = document.querySelector('.cart__total-button');

const groupCartItems = (cartItems) => {
	const groupedCart = [];
	cartItems.forEach((item) => {
		const existingItem = groupedCart.find(({ manufacturerId, productId }) => manufacturerId === item.manufacturerId && productId === item.productId);
		if (existingItem) {
			existingItem.quantity += item.quantity;
		} else {
			groupedCart.push({ ...item });
		}
	});
	return groupedCart;
};

const calculateTotalCost = (groupedCart) => {
	return groupedCart.reduce((total, { price, quantity }) => total + price * quantity, 0);
};

const showCartOrder = () => {
	cartLayoutElement.remove();
	const cartOrder = createCartOrder();
	cartElement.append(cartOrder);
	/*setTimeout(() => {
	    window.location.href = 'index.html';
	}, 5000);*/
};

const updateCartTotalText = (totalItems, totalCost) => {
	const cartTotalText = document.querySelector('.cart__total-text');
	cartTotalText.innerHTML = `В корзине ${totalItems} ${declineNouns(totalItems, ['товар', 'товара', 'товаров'])} на сумму ${splitIntegers(totalCost)} ${declineNouns(totalCost, [
		'рубль',
		'рубля',
		'рублей',
	])}`;
};

const showCartEmpty = () => {
	const cartEmpty = createCartEmpty();
	cartLayoutElement.remove();
	cartElement.append(cartEmpty);
};

const updateCartTotal = () => {
	const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
	const groupedCart = groupCartItems(cartItems);
	const totalItems = groupedCart.reduce((total, { quantity }) => total + quantity, 0);
	const totalCost = calculateTotalCost(groupedCart);
	const isEmpty = !groupedCart.length;
	if (isEmpty) {
		showCartEmpty();
	} else {
		updateCartTotalText(totalItems, totalCost);
	}
};

const createCartList = (groupedCart, totalItems, totalCost, isEmpty) => {
	const cartAddedProductsList = document.createElement('ul');
	cartAddedProductsList.classList.add('cart__added-products-list');
	groupedCart.map(createAddedProductCard).forEach((card) => cartAddedProductsList.append(card));
	cartAddedProductsElement.append(cartAddedProductsList);
	updateCartTotalText(totalItems, totalCost);
	if (isEmpty) {
		showCartEmpty();
	}
};

const loadCart = () => {
	const cartItems = JSON.parse(localStorage.getItem('cart')) ?? [];
	const groupedCart = groupCartItems(cartItems);
	const totalItems = groupedCart.reduce((total, { quantity }) => total + quantity, 0);
	const totalCost = calculateTotalCost(groupedCart);
	const isEmpty = !groupedCart.length;
	createCartList(groupedCart, totalItems, totalCost, isEmpty);
};

if (cartTotalButtonElement && !cartTotalButtonElement.hasAttribute('hidden')) {
	cartTotalButtonElement.addEventListener('click', showCartOrder);
}

if (window.location.pathname === '/cart') {
	loadCart();
}

export { groupCartItems, calculateTotalCost, updateCartTotal };
